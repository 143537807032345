import { motion } from "framer-motion";

const IconTwo = () => {
    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.3,
                    type: "ease",                    duration: 2,
                    bounce: 0,
                },
                opacity: { delay: i * 0.3, duration: 0.01 },
            },
        }),
    };
    return (
        <motion.svg
            id='Layer_2'
            data-name='Layer 2'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            viewBox='0 0 93.2 105.54'
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}}
        >
            <defs>
                <style>
                    {`
            .cls-1 {
              stroke: #293882;
              stroke-miterlimit: 10;
              stroke-width: 1.5px;
            }
            .cls-1, .cls-2 {
              fill: none;
            }
            .cls-2 {
              clip-path: url(#clippath);
            }
          `}
                </style>
                <clipPath id='clippath'>
                    <path
                        className='cls-1'
                        d='m31.17,17.4h34.86v70.75H20.54V28.03c0-5.87,4.77-10.63,10.63-10.63Z'
                        transform='translate(96.06 9.49) rotate(90)'
                    />
                </clipPath>
            </defs>
            <motion.g id='Layer_1-2' data-name='Layer 1'>
                <motion.path
                    className='cls-1'
                    d='m31.04,10.37h41.76v84.81H13.51V27.9c0-9.68,7.86-17.53,17.53-17.53Z'
                    transform='translate(95.92 9.62) rotate(90)'
                    custom={0}
                    variants={draw}
                />
                <motion.path
                    className='cls-1'
                    d='m68.02,16.23H26.31v2.93H7.91v-2.93H.75v73.09h7.16v-2.93h18.4v2.93h66.15v-48.66c0-13.49-10.94-24.43-24.43-24.43Z'
                    custom={0}
                    variants={draw}
                />
                <motion.polyline
                    className='cls-1'
                    points='10.61 98.31 16.64 92.28 22.67 98.31'
                    custom={0}
                    variants={draw}
                />
                <motion.line
                    className='cls-1'
                    x1='16.64'
                    y1='92.28'
                    x2='16.64'
                    y2='105.54'
                    custom={1}
                    variants={draw}
                />
                <motion.polyline
                    className='cls-1'
                    points='22.67 7.23 16.64 13.26 10.61 7.23'
                    custom={1}
                    variants={draw}
                />
                <motion.line
                    className='cls-1'
                    x1='16.64'
                    y1='13.26'
                    x2='16.64'
                    custom={1}
                    variants={draw}
                />
                <motion.g custom={2} variants={draw}>
                    <g className='cls-2'>
                        <g>
                            <motion.line
                                className='cls-1'
                                x1='7.91'
                                y1='30.02'
                                x2='17.82'
                                y2='39.94'
                            />
                            <motion.line
                                className='cls-1'
                                x1='7.91'
                                y1='75.52'
                                x2='17.82'
                                y2='65.61'
                            />
                            <motion.line
                                className='cls-1'
                                x1='75.54'
                                y1='33.14'
                                x2='68.74'
                                y2='39.94'
                            />
                            <motion.line
                                className='cls-1'
                                x1='78.66'
                                y1='75.52'
                                x2='68.74'
                                y2='65.61'
                            />
                        </g>
                    </g>
                    <motion.path
                        className='cls-1'
                        d='m31.17,17.4h34.86v70.75H20.54V28.03c0-5.87,4.77-10.63,10.63-10.63Z'
                        transform='translate(96.06 9.49) rotate(90)'
                    />
                </motion.g>
                <motion.path
                    className='cls-1'
                    d='m31.17,27.31h24.95v50.92h-25.67V28.03c0-.4.32-.72.72-.72Z'
                    transform='translate(96.06 9.49) rotate(90)'
                    custom={3}
                    variants={draw}
                />
            </motion.g>
        </motion.svg>
    );
};
export default IconTwo;
