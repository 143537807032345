import { motion } from "framer-motion";

const IconTen = () => {
    const draw3 = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.3,
                    type: "ease",
                    duration: 2,
                    bounce: 0,
                },
                opacity: { delay: i * 0.3, duration: 0.01 },
            },
        }),
    };

    return (
        <motion.svg
            id='Layer_2'
            data-name='Layer 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 104.26 85.46'
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}}
            className={'smaller'}
        >
            <defs>
                <style>
                    {`
      .cls-333 {
        fill: none;
        stroke: #293882;
        stroke-miterlimit: 10;
        stroke-width: 1.5px;
      }`}
                </style>
            </defs>
            <motion.g id='Layer_1-2' data-name='Layer 1'>
                <g>
                    <g>
                        <motion.polyline
                            className='cls-333'
                            points='44.57 6.77 47.44 3.9 50.32 6.77'
                            custom={0}
                            variants={draw3}
                        />
                        <motion.polyline
                            className='cls-333'
                            points='59.69 3.9 56.82 6.77 53.94 3.9'
                            custom={0}
                            variants={draw3}
                        />
                    </g>
                    <g>
                        <motion.rect
                            className='cls-333'
                            x='.75'
                            y='45.01'
                            width='22.23'
                            height='21.2'
                            custom={1}
                            variants={draw3}
                        />
                        <motion.polygon
                            className='cls-333'
                            points='15.1 52.72 11.86 50.41 8.63 52.72 8.63 45.01 15.1 45.01 15.1 52.72'
                            custom={2}
                            variants={draw3}
                        />
                    </g>
                    <g>
                        <motion.rect
                            className='cls-333'
                            x='.75'
                            y='23.81'
                            width='22.23'
                            height='21.2'
                            custom={1}
                            variants={draw3}
                        />
                        <motion.polygon
                            className='cls-333'
                            points='15.1 31.52 11.86 29.2 8.63 31.52 8.63 23.81 15.1 23.81 15.1 31.52'
                            custom={0}
                            variants={draw3}
                        />
                    </g>
                    <g>
                        <motion.rect
                            className='cls-333'
                            x='81.28'
                            y='45.01'
                            width='22.23'
                            height='21.2'
                            custom={1}
                            variants={draw3}
                        />
                        <motion.polygon
                            className='cls-333'
                            points='95.63 52.72 92.39 50.41 89.16 52.72 89.16 45.01 95.63 45.01 95.63 52.72'
                            custom={1}
                            variants={draw3}
                        />
                    </g>
                    <g>
                        <motion.rect
                            className='cls-333'
                            x='22.98'
                            y='.75'
                            width='58.3'
                            height='65.46'
                            custom={2}
                            variants={draw3}
                        />
                        <motion.polyline
                            className='cls-333'
                            points='31.65 66.21 31.65 9.81 72.61 9.81 72.61 66.21'
                            custom={1}
                            variants={draw3}
                        />
                        <g>
                            <motion.line
                                className='cls-333'
                                x1='76.9'
                                y1='30.39'
                                x2='76.9'
                                y2='33.67'
                                custom={0}
                                variants={draw3}
                            />
                            <motion.line
                                className='cls-333'
                                x1='76.9'
                                y1='36.18'
                                x2='76.9'
                                y2='39.45'
                                custom={1}
                                variants={draw3}
                            />
                        </g>
                    </g>
                    <g>
                        <motion.line
                            className='cls-333'
                            x1='52.13'
                            y1='46.94'
                            x2='52.13'
                            y2='85.46'
                            custom={2}
                            variants={draw3}
                        />
                        <text />
                    </g>
                    <g>
                        <motion.line
                            className='cls-333'
                            x1='31.65'
                            y1='38.01'
                            x2='72.61'
                            y2='38.01'
                            custom={2}
                            variants={draw3}
                        />
                        <motion.line
                            className='cls-333'
                            x1='31.65'
                            y1='32.37'
                            x2='72.61'
                            y2='32.37'
                            custom={1}
                            variants={draw3}

                        />
                        <motion.line
                            className='cls-333'
                            x1='31.65'
                            y1='26.73'
                            x2='72.61'
                            y2='26.73'
                            custom={2}
                            variants={draw3}
                        />
                        <motion.line
                            className='cls-333'
                            x1='31.65'
                            y1='21.09'
                            x2='72.61'
                            y2='21.09'
                            custom={1}
                            variants={draw3}
                        />
                        <motion.line
                            className='cls-333'
                            x1='31.65'
                            y1='15.45'
                            x2='72.61'
                            y2='15.45'
                            custom={0}
                            variants={draw3}
                        />
                        <motion.line
                            className='cls-333'
                            x1='31.65'
                            y1='9.81'
                            x2='72.61'
                            y2='9.81'
                            custom={2}
                            variants={draw3}
                        />
                    </g>
                </g>
            </motion.g>
        </motion.svg>
    );
};

export default IconTen;
