import { useLayoutEffect, useState } from "react";
import "../../scss/global.scss";
import "./home.scss";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectCreative } from "swiper/modules";
import React, { useEffect, useRef } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import IconOne from "../../components/animations/icons/1";
import IconTwo from "../../components/animations/icons/2";
import IconSix from "../../components/animations/icons/6";
import IconFive from "../../components/animations/icons/5";
import IconFour from "../../components/animations/icons/4";
import IconThree from "../../components/animations/icons/3";
import SwipeHintOverlay from "../../components/animations/gesture/swipeHintOverlay";
import LottieAnimation from "react-lottie";
import animation from "./lottie.json";
import { motion } from "framer-motion";
import VerticalLine from "../../components/animations/verticalLine/verticalLine";
import IconSeven from "../../components/animations/icons/7";
import IconEight from "../../components/animations/icons/8";
import IconNine from "../../components/animations/icons/9";
import IconTen from "../../components/animations/icons/10";

const Home: React.FC = () => {
    const svgRef = useRef<SVGSVGElement>(null);
    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const defaultOptions = {
        loop: false,
        autoplay: true,

        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet",
        },
    };
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 800);
    }, [currentSlide]);

    const handleSlideChange = (swiper: any) => {
        setCurrentSlide(swiper.activeIndex);
    };

    return (
        <>
            {currentSlide === 0 ? (
                <div className='big-logo'>
                    <LottieAnimation
                        options={defaultOptions}
                        height={"auto"}
                        width={"100%"}
                    />
                </div>
            ) : (
                <img src='../images/logo.svg' className='logo' />
            )}
            <div className='touch'>
                <a href='/#footer'>
                    <h4
                        style={{
                            color: "white",
                            borderColor: "white",
                        }}
                    >
                        GET IN TOUCH
                    </h4>
                </a>
            </div>
            {isMobile && <SwipeHintOverlay />}
            <SwiperComponent
                pagination={{ clickable: true }}
                modules={[Navigation, EffectCreative]}
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                loop={false}
                initialSlide={0}
                centeredSlides={true}
                slidesPerView={1}
                id='hero-swiper'
                onSlideChange={handleSlideChange}
                effect={"creative"}
                autoHeight={true}
                creativeEffect={{
                    prev: {
                        shadow: true,
                        translate: [0, 0, -400],
                    },
                    next: {
                        translate: ["100%", 0, 0],
                    },
                }}
            >
                <SwiperSlide>
                    <div className='slide-hero one'>
                        <div className='hero-image-one'></div>
                        <div className='content'>
                            <div className='placeholder'>{""}</div>
                            <div className='main-content'>
                                <motion.h3
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 6 }}
                                >
                                    GRADE-A HEADQUARTERS <br/> BUILDING, 20,000 TO 56,573 SQ FT<br/> AVAILABLE IN THE HEART OF<br/> THE CITY CORE
                                </motion.h3>
                                <motion.p
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 6.5 }}
                                >
                                    Situated in the center of it all,<br></br>{" "}
                                    as part of the Tower 42 Estate,<br></br> 15
                                    Bishopsgate...
                                </motion.p>
                            </div>
                            <div className='bot-content'>
                                <motion.h3
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 7 }}
                                >
                                    COMING Q4 2024
                                </motion.h3>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='slide-hero two'>
                        <div className='image-wrapper-one'>
                            <div className='image-one'><p>Computer Generated Image</p></div>
                            <div className='copy'>
                                <p>FULLY REFURBISHED RECEPTION AREAS</p>
                            </div>
                        </div>
                        <div className='image-wrapper-two'>
                            <div className='image-two'><p>Computer Generated Image</p></div>
                            <div className='copy'>
                                <p>CAT A FIT-OUT WRAP AROUND GLAZING</p>
                            </div>
                        </div>
                        <div className='image-wrapper-three'>
                            <div className='copy'>
                                <p>
                                EXAMPLE OF FULLY <br></br> FITTED FLOOR
                                </p>
                            </div>
                            <div className='image-three'><p>Computer Generated Image</p></div>
                        </div>
                        <div className='table-wrapper'>
                            <div className='table'>
                                <div className='row'>
                                    <div className='cell'>Floor</div>
                                    <div className='cell'>SQ FT</div>
                                    <div className='cell'>TERRACE</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>6</div>
                                    <div className='cell'>4,704</div>
                                    <div className='cell'>2,108</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>5</div>
                                    <div className='cell'>7,399</div>
                                    <div className='cell'></div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>4</div>
                                    <div className='cell'>7,275</div>
                                    <div className='cell'>2,393</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>3</div>
                                    <div className='cell'>10,395</div>
                                    <div className='cell'></div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>2</div>
                                    <div className='cell'>10,469</div>
                                    <div className='cell'></div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>1</div>
                                    <div className='cell'>10,477</div>
                                    <div className='cell'></div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>G (Reception)</div>
                                    <div className='cell'>2,323</div>
                                    <div className='cell'></div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>B</div>
                                    <div className='cell'>3,531</div>
                                    <div className='cell'>328</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>total</div>
                                    <div className='cell'>56,573</div>
                                    <div className='cell'>4,501</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h1>
                                <span>...</span>
                                <span className='under'>15</span> HIGHLY
                                FLEXIBLE
                            </h1>
                        </div>
                    </div>
                    <div className='slide-hero two-mobile'>
                        <div className='title'>
                            <h1>
                                <span>...</span>
                                <span className='under'>15</span> HIGHLY
                                FLEXIBLE
                            </h1>
                        </div>
                        <SwiperComponent
                            modules={[Autoplay]}
                            loop={true}
                            initialSlide={0}
                            centeredSlides={true}
                            slidesPerView={1}
                            id='three-swiper'
                            autoplay={{ delay: 5000 }}
                            
                        >
                            <SwiperSlide>
                                <div className='image-wrapper'>
                                    <div className='image one-image'></div>
                                    <div className='copy'>
                                        <p>FULLY REFURBISHED RECEPTION AREAS</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='image-wrapper'>
                                    <div className='image two-image'></div>
                                    <div className='copy'>
                                        <p>FULLY REFURBISHED RECEPTION AREAS</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='image-wrapper'>
                                    <div className='image three-image'></div>
                                    <div className='copy'>
                                        <p>FULLY REFURBISHED RECEPTION AREAS</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </SwiperComponent>

                        <div className='table-wrapper'>
                            <div className='table'>
                                <div className='row'>
                                    <div className='cell'>Floor</div>
                                    <div className='cell'>SQ FT</div>
                                    <div className='cell'>SQ M</div>
                                </div>
                                <div className='row terrace'>
                                    <div className='cell'>6</div>
                                    <div className='cell'>4,704</div>
                                    <div className='cell'>437</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>TERRACE</div>
                                    <div className='cell'>2,108</div>
                                    <div className='cell'>196</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>5</div>
                                    <div className='cell'>7,399</div>
                                    <div className='cell'>687</div>
                                </div>
                                <div className='row terrace'>
                                    <div className='cell'>4</div>
                                    <div className='cell'>7,275</div>
                                    <div className='cell'>676</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>TERRACE</div>
                                    <div className='cell'>2,393</div>
                                    <div className='cell'>222</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>3</div>
                                    <div className='cell'>10,395</div>
                                    <div className='cell'>966</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>2</div>
                                    <div className='cell'>10,469</div>
                                    <div className='cell'>972</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>1</div>
                                    <div className='cell'>10,477</div>
                                    <div className='cell'>973</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>G (Reception)</div>
                                    <div className='cell'>2,323</div>
                                    <div className='cell'>216</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>B (storage)</div>
                                    <div className='cell'>3,531</div>
                                    <div className='cell'>328</div>
                                </div>
                                <div className='row'>
                                    <div className='cell'>total</div>
                                    <div className='cell'>56,573</div>
                                    <div className='cell'>5,255</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='slide-hero three'>
                        <div className='background-image'></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='slide-hero four'>
                        <div className='icons-top'>
                            <div className='icon-wrapper'>
                                <div className='copy'>
                                    <p>
                                        109 CYCLE
                                        <br />
                                        PARKING SPACES
                                    </p>
                                </div>
                                <div className='icon'>
                                    <IconOne />
                                </div>
                            </div>
                            <VerticalLine />
                            <div className='icon-wrapper'>
                                <div className='copy'>
                                    <p>
                                        DUAL
                                        <br />
                                        ENTRANCES
                                    </p>
                                </div>
                                <div className='icon'>
                                    <IconTwo />
                                </div>
                            </div>
                            <VerticalLine />
                            <div className='icon-wrapper'>
                                <div className='copy'>
                                    <p>
                                        1:8 DESIGN<br></br>
                                        DENSITY
                                    </p>
                                </div>
                                <div className='icon'>
                                    <IconThree />
                                </div>
                            </div>
                            <VerticalLine />
                            <div className='icon-wrapper'>
                                <div className='copy'>
                                    <p>
                                        LOADING BAY &<br></br>GOOD LIFTS
                                        
                                    </p>
                                </div>
                                <div className='icon'>
                                    <IconTen />
                                </div>
                            </div>                                                       
                        </div>
                        <h1>
                            <span className='under'>15</span> FULL OF FEATURES
                        </h1>
                        <div className='icons-top'>
                            <div className='icon-wrapper'>
                                <div className='icon'>
                                    <IconFour />
                                </div>
                                <div className='copy'>
                                    <p>
                                        HIGHLY SUSTAINABLE -<br></br> BREEAM
                                        EXCELLENT
                                    </p>
                                </div>
                            </div>
                            <VerticalLine />
                            <div className='icon-wrapper'>
                                <div className='icon'>
                                    <IconFive />
                                </div>
                                <div className='copy'>
                                    <p>
                                        TWO ROOF<br></br> TERRACES
                                    </p>
                                </div>
                            </div>                            
                            <VerticalLine />
                            <div className='icon-wrapper'>
                                <div className='icon'>
                                    <IconSix />
                                </div>
                                <div className='copy'>
                                    <p>
                                        FEATURE<br></br>STAIRCASE
                                    </p>
                                </div>
                            </div>
                            <VerticalLine />
                            <div className='icon-wrapper'>
                                <div className='icon'>
                                    <IconSeven />
                                </div>
                                <div className='copy'>
                                    <p>
                                        CAR PARKING<br></br>AVAILABLE
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='four-mobile'>
                        <div className='title'>
                            <h1>
                                <span className='under'>15</span> FULL OF
                                FEATURES
                            </h1>
                        </div>
                        <div className='icons-wrapper'>
                            <div className='icons'>
                                <div className='icon'>
                                    <div className='icon-image'>
                                        <IconOne />
                                    </div>
                                    <div className='icon-copy'>
                                        <p>
                                            109 CYCLE <br></br> PARKING SPACES
                                        </p>
                                    </div>
                                </div>
                                <div className='icon'>
                                    <div className='icon-image'>
                                        <IconTwo />
                                    </div>
                                    <div className='icon-copy'>
                                        <p>
                                            DUAL <br></br> ENTRANCES
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='icons'>
                                <div className='icon'>
                                    <div className='icon-image'>
                                        <IconThree />
                                    </div>
                                    <div className='icon-copy'>
                                        <p>
                                            {" "}
                                            1:8 DESIGN <br></br> DENSITY
                                        </p>
                                    </div>
                                </div>
                                <div className='icon'>
                                    <div className='icon-image'>
                                        <IconFour />
                                    </div>
                                    <div className='icon-copy'>
                                        <p>
                                            HIGHLY SUSTAINABLE - <br></br>{" "}
                                            BREEAM EXCELLENT
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='icons'>
                                <div className='icon'>
                                    <div className='icon-image'>
                                        <IconFive />
                                    </div>
                                    <div className='icon-copy'>
                                        <p>
                                            {" "}
                                            TWO ROOF<br></br> TERRACES
                                        </p>
                                    </div>
                                </div>
                                <div className='icon'>
                                    <div className='icon-image'>
                                        <IconSix />
                                    </div>
                                    <div className='icon-copy'>
                                        <p>
                                            FEATURE <br></br> STAIRCASE
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='icons'>
                                <div className='icon'>
                                    <div className='icon-image'>
                                        <IconSeven />
                                    </div>
                                    <div className='icon-copy'>
                                        <p>
                                            CAR PARKING <br></br> AVAILABLE
                                        </p>
                                    </div>
                                </div>
                                <div className='icon'>
                                    <div className='icon-image'>
                                        <IconTen />
                                    </div>
                                    <div className='icon-copy'>
                                        <p>
                                            LOADING BAYS & <br></br> GOOD LIFTS
                                        </p>
                                    </div>
                                </div>
                            </div>                           
                        </div>
                    </div>
                </SwiperSlide>
                <div
                    className='swiper-button-next'
                    style={{
                        content: `URL('../images/arrow.png')`,
                    }}
                ></div>
                <div
                    className='swiper-button-prev'
                    style={{
                        content: `URL('../images/arrow.png')`,
                    }}
                ></div>
            </SwiperComponent>
        </>
    );
};

export default Home;
