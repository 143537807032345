import { motion } from "framer-motion";

const IconOne = () => {
    const draw1 = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.25,
                    type: "ease",                    duration: 2,
                    bounce: 0,
                },
                opacity: { delay: i * 0.25, duration: 0.01 },
            },
        }),
    };
    return (
        <motion.svg
            id='Layer_2'
            data-name='Layer 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 104.27 67.68'
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}}
        >
            <defs>
                <style>
                    {`.cls-1 {
fill: none;
stroke: #293882;
stroke-miterlimit: 10;
stroke-width: 1.5px;
}`}
                </style>
            </defs>
            <motion.g id='Layer_1-2' data-name='Layer 1'>
                <motion.ellipse
                    className='cls-1'
                    cx='83.84'
                    cy='47.5'
                    rx='19.68'
                    ry='19.43'
                    custom={0}
                    variants={draw1}
                />
                <motion.ellipse
                    className='cls-1'
                    cx='20.43'
                    cy='47.5'
                    rx='19.68'
                    ry='19.43'
                    custom={2}
                    variants={draw1}
                />
                <motion.path
                    className='cls-1'
                    d='M22.75,4.29h16.08M29.17,4.29l21.55,43.58M20.43,47.5l13.93-32.07h37.98l.04.07-21.67,32.37-30.29-.37ZM83.84,47.87L67.65.75h-12.6'
                    custom={3}
                    variants={draw1}
                />
            </motion.g>
        </motion.svg>
    );
};

export default IconOne;
