import { motion } from "framer-motion";

const IconSix = () => {


    const draw3 = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.3,
                    type: "ease",                    duration: 2,
                    bounce: 0,
                },
                opacity: { delay: i * 0.3, duration: 0.01 },
            },
        }),
    };

    return (
        <motion.svg
        id='Layer_2'
        data-name='Layer 2'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 115.64 75.51'
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}}
    >
        <defs>
            <style>
                {`
.cls-5, .cls-6 {
fill: none;
stroke: #293882;
stroke-miterlimit: 10;
stroke-width: 1.5px;
}

.cls-6 {
stroke-linecap: square;
}`}
            </style>
        </defs>
        <motion.g id='Layer_1-2' data-name='Layer 1'>
            <g>
                <motion.line
                    className='cls-5'
                    x1='104.85'
                    y1='3.93'
                    x2='104.85'
                    y2='26.13'
                    custom={0}
                    variants={draw3}
                />
                <motion.polygon
                    className='cls-6'
                    points='63.11 20.28 63.11 31.17 49.3 31.17 49.3 42.07 35.49 42.07 35.49 52.96 21.67 52.96 21.67 63.86 7.86 63.86 7.86 74.76 49.6 74.76 49.6 63.86 63.41 63.86 63.41 52.96 77.22 52.96 77.22 42.07 91.03 42.07 91.03 31.17 104.85 31.17 104.85 20.28 63.11 20.28'
                    custom={0}
                    variants={draw3}
                />
                <motion.line
                    className='cls-5'
                    x1='7.86'
                    y1='47.52'
                    x2='7.86'
                    y2='74.76'
                    custom={0}
                    variants={draw3}
                />
                <motion.line
                    className='cls-6'
                    x1='63.11'
                    y1='3.93'
                    x2='63.11'
                    y2='24.1'
                    custom={1}
                    variants={draw3}
                />
                <motion.polygon
                    className='cls-5'
                    points='104.85 74.76 49.6 74.76 49.6 63.86 63.41 63.86 63.41 52.96 77.22 52.96 77.22 42.07 91.03 42.07 91.03 31.17 104.85 31.17 104.85 74.76'
                    custom={1}
                    variants={draw3}
                />
                <motion.path
                    className='cls-6'
                    d='m114.89,6.78v-1.79c0-2.34-1.9-4.23-4.23-4.23h-.41c-.9,0-1.77.3-2.47.86L42.97,52.73'
                    custom={1}
                    variants={draw3}                                                />
                <motion.path
                    className='cls-6'
                    d='m73.27,6.78v-1.79c0-2.34-1.9-4.23-4.23-4.23h-.41c-.9,0-1.77.3-2.47.86L1.05,52.96'
                    custom={2}
                    variants={draw3}
                />
                <motion.line
                    className='cls-5'
                    x1='49.6'
                    y1='47.52'
                    x2='49.6'
                    y2='75.29'
                    custom={2}
                    variants={draw3}
                />
                <motion.line
                    className='cls-6'
                    x1='49.3'
                    y1='42.07'
                    x2='77.22'
                    y2='42.07'
                    custom={2}
                    variants={draw3}
                />
                <motion.line
                    className='cls-6'
                    x1='35.49'
                    y1='52.96'
                    x2='63.41'
                    y2='52.96'
                    custom={3}
                    variants={draw3}
                />
                <motion.line
                    className='cls-6'
                    x1='21.67'
                    y1='63.86'
                    x2='49.6'
                    y2='63.86'
                    custom={3}
                    variants={draw3}
                />
                <motion.line
                    className='cls-6'
                    x1='63.11'
                    y1='31.17'
                    x2='91.03'
                    y2='31.17'
                    custom={3}
                    variants={draw3}
                />
            </g>
        </motion.g>
    </motion.svg>
    );
    };

    export default IconSix;