import { motion } from "framer-motion";

const IconSeven = () => {
    const draw3 = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.3,
                    type: "ease",
                    duration: 2,
                    bounce: 0,
                },
                opacity: { delay: i * 0.3, duration: 0.01 },
            },
        }),
    };

    return (
        <motion.svg
            id='Layer_2'
            data-name='Layer 2'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 115.34 54.93'
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}}
        >
            <defs>
                <style>
                    {`
      .cls-111 {
        fill: none;
        stroke: #293882;
        stroke-miterlimit: 10;
        stroke-width: 1.5px;
      }`}
                </style>
            </defs>
            <motion.g id='Layer_1-2' data-name='Layer 1'>
                <g id='g3600'>
                    <g id='g3602'>
                        <motion.path
                            id='path3604'
                            className='cls-111'
                            d='m86.71,44.89H28.16'
                            custom={0}
                            variants={draw3}
                        />
                    </g>
                    <g>
                        <motion.path
                            id='path3620'
                            className='cls-111'
                            d='m9.87,42.57l-9.12-1.88v-16.71c0-2.57,2.08-4.65,4.65-4.65h96.16s13.04,9.29,13.04,9.29v16.26h-9.29'
                            custom={0}
                            variants={draw3}
                        />
                        <motion.path
                            id='path3616'
                            className='cls-111'
                            d='m12.37,19.34l4.79-7.66C21.4,4.88,28.85.75,36.87.75h21.64c5.31,0,10.52,1.47,15.02,4.3,4.17,2.62,13.18,14.29,13.18,14.29'
                            custom={0}
                            variants={draw3}
                        />
                    </g>
                    <motion.path
                        id='path3624'
                        className='cls-111'
                        d='m86.71,44.89c0-5.13,4.16-9.29,9.29-9.29s9.29,4.16,9.29,9.29-4.16,9.29-9.29,9.29-9.29-4.16-9.29-9.29Z'
                        custom={1}
                        variants={draw3}
                    />
                    <motion.path
                        id='path3632'
                        className='cls-111'
                        d='m9.58,44.89c0-5.13,4.16-9.29,9.29-9.29s9.29,4.16,9.29,9.29-4.16,9.29-9.29,9.29-9.29-4.16-9.29-9.29Z'
                        custom={1}
                        variants={draw3}
                    />
                    <motion.path
                        id='path3640'
                        className='cls-111'
                        d='m55.33,28.63h5.83'
                        custom={2}
                        variants={draw3}
                    />
                    <motion.path
                        id='path3644'
                        className='cls-111'
                        d='m105.3,28.63h9.29'
                        custom={2}
                        variants={draw3}
                    />
                    <motion.path
                        id='path3648'
                        className='cls-111'
                        d='m12.37,19.34v1.92c0,4.07-3.3,7.38-7.38,7.38H.75'
                        custom={2}
                        variants={draw3}
                    />
                    <motion.path
                        id='path3652'
                        className='cls-111'
                        d='m49.54.75v44.14'
                        custom={3}
                        variants={draw3}
                    />
                </g>
            </motion.g>
        </motion.svg>
    );
};

export default IconSeven;
