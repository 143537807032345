import { motion } from "framer-motion";


const IconFive = () => {

    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.35,
                    type: "ease",                    duration: 2,
                    bounce: 0,
                },
                opacity: { delay: i * 0.35, duration: 0.01 },
            },
        }),
    };

    return (
        <motion.svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 96.85 81.22" initial='hidden' whileInView='visible' viewport={{once: true}}>
  <defs>
    <style>{`
      .cls-11 {
        stroke: #293882;
        stroke-miterlimit: 10;
        stroke-width: 1.5px;
      }

      .cls-11, .cls-21 {
        fill: none;
      }

      .cls-21 {
        
      }
      `}
    </style>
    <clipPath id="clippath">
      <path className="cls-11" d="m94.26,28.9H2.59L46.04,1.43c1.44-.91,3.27-.91,4.71,0l43.51,27.46Z"/>
    </clipPath>
  </defs>
  <motion.g id="Layer_1-2" data-name="Layer 1">
    <g>
      <g>
        <motion.g className="cls-21" variants={draw} custom={0}>
          <g>
            <motion.line className="cls-11" x1="50.51" y1="-2.88" x2="24.47" y2="31.7" variants={draw} custom={0} />
            <motion.line className="cls-11" x1="45.91" y1="-3.33" x2="71.42" y2="30.43"variants={draw} custom={0} />
          </g>
        </motion.g>
        <motion.path className="cls-11" d="m94.26,28.9H2.59L46.04,1.43c1.44-.91,3.27-.91,4.71,0l43.51,27.46Z" variants={draw} custom={0}/>
      </g>
      <motion.line className="cls-11" x1="48.42" y1="1.09" x2="48.42" y2="56.9" variants={draw} custom={1} />
      <g>
        <motion.line className="cls-11" x1="29.1" y1="56.9" x2="67.75" y2="56.9" variants={draw} custom={1}/>
        <motion.line className="cls-11" x1="48.42" y1="56.9" x2="48.42" y2="80.48" variants={draw} custom={1}/>
        <motion.line className="cls-11" x1="43.07" y1="80.47" x2="53.78" y2="80.47" variants={draw} custom={1}/>
      </g>
      <g>
        <g>
          <motion.path className="cls-11" d="m72.37,66.72h13.52c4.85,0,8.79-3.94,8.79-8.79v-12.81" variants={draw} custom={2}/>
          <motion.line className="cls-11" x1="77.87" y1="66.72" x2="72.85" y2="80.48" variants={draw} custom={2}/>
          <motion.line className="cls-11" x1="89.66" y1="66.72" x2="94.68" y2="80.48" variants={draw} custom={2}/>
        </g>
        <g>
          <motion.path className="cls-11" d="m24.47,66.72h-13.52c-4.85,0-8.79-3.94-8.79-8.79v-12.81" variants={draw} custom={3}/>
          <motion.line className="cls-11" x1="18.98" y1="66.72" x2="24" y2="80.48" variants={draw} custom={3} />
          <motion.line className="cls-11" x1="7.18" y1="66.72" x2="2.16" y2="80.48" variants={draw} custom={3} />
        </g>
      </g>
    </g>
  </motion.g>
</motion.svg>
    )

}

export default IconFive;