// SwipeHintOverlay.tsx
import React, { useEffect, useState } from 'react';
import styles from './swipeHintOverlay.module.scss';
import animationData from './finger.json';
import Lottie from 'react-lottie';

const SwipeHintOverlay: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const lottieOptions = {
    loop: 2,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };


  useEffect(() => {
    
    
      setVisible(true);
        
    const timer = setTimeout(() => {
      setVisible(false);
    }, 2000);
    return () => {clearTimeout(timer);};
  }, []);

  return (
    <div className={`${styles['swipe-hint-overlay']} ${visible ? styles.visible : ''}`}>
        <div>
          <Lottie options={lottieOptions} height={300} width={300} />
        </div>
    </div>
  );
};

export default SwipeHintOverlay;