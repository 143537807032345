import { motion } from "framer-motion";


const IconThree = () => {
    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.3,
                    type: "ease",
                    duration: 2,
                    bounce: 0,
                },
                opacity: { delay: i * 0.3, duration: 0.01 },
            },
        }),
    };
    return (
      <motion.svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.21 100.18" initial='hidden' whileInView='visible' viewport={{once: true}}>
      <defs>
        <style>{`
          .cls-1 {
            fill: none;
            stroke: #293882;
            stroke-miterlimit: 10;
            stroke-width: 1.5px;
          }
    
          .cls-2 {
            letter-spacing: -.04em;
          }
    
          .cls-3 {
            fill: #293882;
            font-family: Brown-Light, Brown;
            font-size: 29.64px;
            font-weight: 300;
          }
    
          .superscript {
            fill: #293882;
            font-size: 14px; /* Adjust font-size for superscript */
            dy: -10px; /* Adjust vertical position for superscript */
          }`}
        </style>
      </defs>
      <motion.g id="Layer_1-2" data-name="Layer 1">
        <g>
          <g>
            <text className="cls-3" transform="translate(42.09 55.88)">
              <tspan className="cls-25" x="0" y="0">8</tspan>
              <tspan x="15.71" y="0">m</tspan>
              <tspan className="superscript" x="40" y="-15">2</tspan>
            </text>
            <g>
              <g>
                <motion.path className="cls-1" d="m26.44,54.93h8.48v-19.6c0-9.44-7.65-17.09-17.09-17.09h0C8.4,18.24.75,25.89.75,35.33v19.6s8.48,0,8.48,0" variants={draw} custom={0}/>
                <motion.circle className="cls-1" cx="17.84" cy="9.5" r="8.75" variants={draw} custom={1}/>
              </g>
              <motion.polyline className="cls-1" points="26.44 37.52 26.44 86.43 9.24 86.43 9.24 37.52" variants={draw} custom={2}/>
              <motion.line className="cls-1" x1="17.84" y1="86.43" x2="17.84" y2="54.93" variants={draw} custom={3}/>
            </g>
          </g>
          <motion.line className="cls-1" x1=".75" y1="86.43" x2="92.32" y2="86.43" variants={draw} custom={4} />
        </g>
      </motion.g>
    </motion.svg>
    )
}

export default IconThree;
