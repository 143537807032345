import { motion } from "framer-motion";

const IconFour = () => {
    const draw4 = {
        hidden: { opacity: 0 },
        visible: (i: number) => ({
            
            opacity: 1,
            transition: {
              duration: 1
            }
        }),
    };
    return (
        <motion.svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.4 51.78" initial='hidden' whileInView='visible' viewport={{once: true}}>
  <defs>
    <style>{`
      .cls-12 {
        fill: #293882;
      }`}
    </style>
  </defs>
  <motion.g id="Layer_1-2" data-name="Layer 1">
    <motion.g>
      <g>
        <motion.path className="cls-12" d="m51.77,3.26c.94,0,1.5-.51,1.5-1.54s-.56-1.58-1.5-1.58h-10.94c-1.11,0-1.58.47-1.58,1.58v25.35c0,1.11.47,1.58,1.58,1.58h10.94c.94,0,1.5-.56,1.5-1.58s-.56-1.54-1.5-1.54h-9.4v-9.61h6.67c.94,0,1.45-.51,1.45-1.54s-.51-1.58-1.45-1.58h-6.67V3.26h9.4Z" variants={draw4} custom={0}/>
        <motion.path className="cls-12" d="m83.25,1.55c-.35-1.07-.69-1.5-1.71-1.5s-1.37.43-1.71,1.5l-8.04,25.01c-.17.55-.38,1.49,1,1.94,1.21.39,1.82-.4,1.99-.92l1.8-5.64h9.92l1.8,5.64c.17.52.73,1.4,2.07.95,1.24-.42,1.09-1.46.96-1.93L83.25,1.55Zm2.14,17.36h-7.7l.03-.09h-.03l3.84-11.76,3.85,11.76h-.03l.03.09Z" variants={draw4} custom={0}/>
        <motion.path className="cls-12" d="m111.72.14c-1.07,0-1.2.51-1.84,1.58l-6.41,10.99-6.41-10.99c-.64-1.07-.77-1.58-1.84-1.58s-1.58.47-1.58,1.58v25.35c0,1.11.47,1.58,1.58,1.58s1.54-.47,1.54-1.58V7.58l5,8.43c.39.64.78,1.33,1.71,1.33s1.33-.68,1.71-1.33l5-8.43v19.5c0,1.11.43,1.58,1.54,1.58s1.58-.47,1.58-1.58V1.72c0-1.11-.47-1.58-1.58-1.58" variants={draw4} custom={0}/>
        <motion.path className="cls-12" d="m68.33,3.26c.94,0,1.5-.51,1.5-1.54s-.56-1.58-1.5-1.58h-10.95c-1.11,0-1.58.47-1.58,1.58v25.35c0,1.11.47,1.58,1.58,1.58h10.95c.94,0,1.5-.56,1.5-1.58s-.56-1.54-1.5-1.54h-9.41v-9.61h6.68c.94,0,1.45-.51,1.45-1.54s-.51-1.58-1.45-1.58h-6.68V3.26h9.41Z" variants={draw4} custom={1}/>
        <motion.path className="cls-12" d="m30.87,15.82c1.5-.3,2.79-1.07,3.81-2.24,2.11-2.44,1.81-5.74,1.81-5.74,0-4.57-2.97-7.64-7.39-7.64,0,0-7.65.01-7.75.03-.88.09-1.26.56-1.26,1.56v25.36c0,1.11.47,1.58,1.58,1.58s1.54-.47,1.54-1.58v-11.21h4.18l6.53,11.9c.54.97,1.17,1.16,2.15.62.97-.53,1.12-1.15.59-2.13l-5.78-10.51Zm-7.66-3.03V3.31h5.88c2.71,0,4.27,1.65,4.27,4.52,0,0,.32,2.06-1.06,3.72-.73.88-1.71,1.24-2.95,1.24h-6.14Z" variants={draw4} custom={2}/>
        <motion.path className="cls-12" d="m119.76,0c-2.3,0-3.9,1.76-3.9,3.79s1.5,3.73,3.82,3.73c1.81,0,3.73-1.62,3.73-3.83,0-2.08-1.75-3.69-3.64-3.69m.01,7.03c-2.17,0-3.41-1.63-3.41-3.23,0-2,1.53-3.31,3.21-3.31,2.03,0,3.31,1.69,3.31,3.15,0,2.05-1.58,3.38-3.11,3.38" variants={draw4} custom={1}/>
        <motion.path className="cls-12" d="m121.34,2.78c0-.88-.51-1.43-1.36-1.43h-1.33c-.19,0-.26.09-.26.28v4.28c0,.19.07.27.26.27s.26-.09.26-.27v-1.66h.82l1,1.79c.16.28.51.12.51-.1,0-.1-.03-.14-.06-.19l-.84-1.52c.58-.06,1-.52,1-1.45m-1.2.93h-1.23v-1.82h1.21c.3,0,.7.26.7.9,0,.59-.23.93-.68.93" variants={draw4} custom={1}/>
        <motion.path className="cls-12" d="m14.17,13.95c.14-.14.28-.28.41-.43,2.09-2.32,1.81-5.74,1.81-5.74C16.39,3.21,13.42.14,9,.14H1.56C.7.14,0,.84,0,1.7v25.31c0,.86.7,1.56,1.56,1.56h8.53c4.42,0,7.39-3.07,7.39-7.64,0,0,.31-3.39-1.81-5.74-.46-.51-1.35-1.16-1.5-1.24M3.12,3.26h5.88c2.71,0,4.27,1.65,4.27,4.52,0,0,.24,2.25-1.06,3.72-.76.86-1.71,1.3-2.95,1.3H3.12V3.26Zm6.97,22.2H3.12v-9.54h7.23c1.24,0,2.2.42,2.95,1.3,1.07,1.25,1.06,3.72,1.06,3.72,0,2.87-1.56,4.52-4.27,4.52" variants={draw4} custom={2}/>
      </g>
      <g>
        <motion.polygon className="cls-12" points="30.29 40.38 31.71 44.74 36.29 44.74 32.58 47.43 34 51.78 30.29 49.09 26.59 51.78 28 47.43 24.3 44.74 28.88 44.74 30.29 40.38"variants={draw4} custom={2}/>
        <motion.polygon className="cls-12" points="47.27 40.38 48.68 44.74 53.26 44.74 49.56 47.43 50.97 51.78 47.27 49.09 43.56 51.78 44.97 47.43 41.27 44.74 45.85 44.74 47.27 40.38" variants={draw4} custom={3}/>
        <motion.polygon className="cls-12" points="64.24 40.38 65.65 44.74 70.23 44.74 66.53 47.43 67.94 51.78 64.24 49.09 60.53 51.78 61.95 47.43 58.24 44.74 62.82 44.74 64.24 40.38" variants={draw4} custom={3}/>
        <motion.polygon className="cls-12" points="81.21 40.38 82.63 44.74 87.21 44.74 83.5 47.43 84.92 51.78 81.21 49.09 77.5 51.78 78.92 47.43 75.21 44.74 79.79 44.74 81.21 40.38" variants={draw4} custom={3}/>
      </g>
    </motion.g>
  </motion.g>
</motion.svg>
    );
}

export default IconFour;