import { motion } from "framer-motion";
import './vertical.scss';

const VerticalLine = () => {
    return (
        <motion.div
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 2 }}
            className='vertical-line'
        >{" "}</motion.div>
    );
};

export default VerticalLine;
